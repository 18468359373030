import { loginByPhone, loginByName } from '@/api/common'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import { routerDispose } from "@/utils/routerDispose";
const state = {
  userInfo: {
    token: '',
    avatar: '',
    menu: [],
    userName: ''
  },
  tag: [],
  isCollapse: false
}

const mutations = {
  //动态路由
  ADD_ROUTE_MENU(state, val) {
    state.userInfo.menu = val
  },
  //修改个人信息
  SET_USER_INFO_ADMIN(state, val) {
    state.userInfo.admin.avatar = val.avatar
    state.userInfo.admin.real_name = val.real_name
  },
  //导航栏菜单折叠
  SET_COLLAPSE(state) {
    state.isCollapse = !state.isCollapse
  },
  SET_USER_INFO(state, val) {
    state.userInfo = val
  },
  ADD_ROUTE(state) {
    let routerList = JSON.parse(JSON.stringify(state.userInfo.menu))
    if (router.getRoutes().length == 3) {
      routerList = routerDispose(routerList)//路由动态添加
      routerList.forEach((e) => {
        router.addRoute(e)
      })
    }
  },
  ADD_TAG(state, tag) {
    let tags = state.tag
    let inx = tags.findIndex(f => f.path === tag.path);
    if (inx == -1) {
      state.tag.push(tag)
    }
  },
  DEL_TAG(state, tag) {
    let tags = state.tag
    let inx = tags.findIndex(f => f.path === tag.path);
    if (inx) {
      state.tag.splice(inx, 1)
      let obj = state.tag[inx - 1]
      router.replace(obj.path)
    }
  },
  DEL_TAG_ALL(state, tag) {
    state.tag = [];
  }
}

const actions = {
  //动态路由
  addRouter({ commit }, val) {
    commit('ADD_ROUTE_MENU', val);
  },
  //个人信息
  editAdmin({ commit }, admin) {
    commit('SET_USER_INFO_ADMIN', admin);
  },
  // user login
  login({ commit }, userInfo) {
    if (userInfo[1] == 1) {
      const { phone, code } = userInfo[0]
      return new Promise((resolve, reject) => {
        let params = {
          phone,
          code
        }
        loginByPhone(params).then(res => {
          if (res.err_no == 0) {
            commit('SET_USER_INFO', res.results);
            resolve(res.results)
          }
        }).catch(error => {
          reject(error)
        })
      })
    } else {
      const { phone, captcha, password, key } = userInfo[0]
      return new Promise((resolve, reject) => {
        let params = {
          name: phone,
          captcha,
          password,
          key
        }
        loginByName(params).then(res => {
          if (res.err_no == 0) {
            commit('SET_USER_INFO', res.results);
            resolve(res.results)
          }
        }).catch(error => {
          reject(error)
        })
      })
    }

  },
  // user logout
  logout({ commit, state, dispatch }) {
    router.replace({ path: '/login' });
    commit('SET_USER_INFO', {});
    commit('DEL_TAG_ALL');
    location.reload()
  },
  //添加路由
  addRoute({ commit }) {
    commit('ADD_ROUTE')
  },
  //添加标签
  addTag({ commit }, tag) {
    commit('ADD_TAG', tag)
  },
  //删除标签
  delTag({ commit }, tag) {
    commit('DEL_TAG', tag)
  },
  //收展菜单
  setCollapse({ commit }) {
    commit('SET_COLLAPSE')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
