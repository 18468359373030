<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {

    // // 禁止缩放
    // window.addEventListener(
    //   "mousewheel",
    //   function (event) {
    //     if (event.ctrlKey === true || event.metaKey) {
    //       event.preventDefault();
    //     }
    //   },
    //   { passive: false }
    // );
    // //firefox
    // window.addEventListener(
    //   "DOMMouseScroll",
    //   function (event) {
    //     if (event.ctrlKey === true || event.metaKey) {
    //       event.preventDefault();
    //     }
    //   },
    //   { passive: false }
    // );
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }

    window.onresize = () => {
      return (() => {
        // 页面变化监听尺寸
        // this.bodyScale()
      })()
    }

  },

  onUnmounted() {
    //销毁
    window.removeEventListener("popstate", this.goBack);
  },
  methods: {

    // 获取当前屏幕尺寸函数
    bodyScale() {
      let devicewidth = document.documentElement.clientWidth//获取当前分辨率下的可是区域宽度
      let scale = devicewidth / 1920 // 分母——设计稿的尺寸
      document.body.style.zoom = scale//放大缩小相应倍数
    },
    goBack() {
      // this.$router.replace({
      //   path: "/system/personalCenter",
      // });
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
