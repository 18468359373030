
//获取菜单权限
export function getMenu(routerList) {
    //构建树
    const tree = (items) => {
        let tree = items.filter(item => item.pid === 0); // 筛选出顶级父节点

        const findChildren = (items, id) => {
            return items.filter(item => item.pid === id); // 筛选出父节点的子节点
        };

        const buildTreeRec = (parent) => {
            parent.path = parent.router
            parent.meta = { title: parent.name, icon: parent.icon }
            delete parent.name
            if (!parent.children || !parent.children.length) {
                parent.children = findChildren(items, parent.id);
                if (!findChildren(items, parent.id).length) {
                    delete parent.children
                }
            }
            if (parent.children && parent.children.length > 0) {
                parent.children.forEach(buildTreeRec); // 递归构建子节点
                parent.redirect = parent.children[0].path
            }
            if (!parent.pid) {
                parent.component = resolve => { require([`@/layout`], resolve) }
                if (!parent.children) {
                    parent.meta.layer = true
                    parent.children = [{
                        path: parent.path,
                        router: parent.path,
                        meta: {
                            title: parent.meta.title,
                            icon: parent.meta.icon
                        },
                        component: resolve => { require([`@/views${parent.router}`], resolve) }
                    }]
                }
            } else {
                parent.component = resolve => { require([`@/views${parent.router}`], resolve) }
            }
            return parent;
        };

        tree.forEach(buildTreeRec); // 开始构建树

        return tree;
    }
    let menu = tree(routerList)
    menu = menu.sort((a, b) => {
        if (a.path == '/home/index') {
            return -1
        }
    })
    return menu;
}
//多级路由嵌套
export function getRouter(menuList) {
    const menu = (items) => {
        if (items.children && items.children.length > 0) {
            items.children.forEach(menu)
            items.children.forEach((v) => {
                if (v.children) {
                    items.children = [...items.children, ...v.children]
                    delete v.children
                }
            })
        }
        return items
    }
    menuList.forEach(menu)
    return menuList
}