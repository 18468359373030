import router from '@/router'
import store from '@/store'
import { routerDispose } from "@/utils/routerDispose";
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth'
import { getMenu, getRouter } from '@/utils/dynamicRouting'
NProgress.configure({ showSpinner: false })
let addFlag = false
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  const hasToken = getToken()
  if (hasToken) {
    if (to.path !== '/login') {
      if (addFlag) {
        next();
      } else {
        gotoRouter(to, next, 1)
      }
    } else {
      gotoRouter(to, next, 2)
    }
    NProgress.done()
  } else {
    if (to.path === '/login') {
      next();
    } else {
      next('/login');
    }
    NProgress.done()
  }
})

router.afterEach(() => {
  NProgress.done()
})
//获取菜单权限--动态路由添加
export function gotoRouter(to, next, type) {
  let power = JSON.parse(JSON.stringify(store.getters?.userInfo?.power || []))
  let routerList = getRouter(getMenu(power))
  if (type == 2) {
    next({ ...routerList[0] })
    return
  }
  routerList.forEach(e => { router.addRoute(e) })//循环添加路由
  router.addRoute({ path: "*", redirect: { path: "/404" } })
  addFlag = true
  if (to.path == '/') {
    next({ ...routerList[0] })
    return
  }
  next({ ...to })

}