import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import qs from 'qs'
import { Loading } from 'element-ui'
let loadingInstance //Loading
let requestCount = 0 //接口请求次数
let time //定时器
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "applicaton/json",
    'Access-Control-Allow-Origin': '*'
  },
  timeout: 5000 // request timeout
})
service.interceptors.request.use(
  config => {
    if (config.requestBaseUrl === 'api') {
      config.baseURL = process.env.VUE_APP_BASE_API
    } else {
      config.baseURL = process.env.VUE_APP_BASE_API_AGENT
    }
    config.headers['token'] = getToken()
    if (config.loading) {
      // 判断loading参数，然后打开全局Loading
      // Loading.service();
      if (time) clearTimeout(time)
      requestCount++
      loadingInstance = Loading.service();   //开启Loading加载
    }
    // config.data = qs.stringify(config.data)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    if (response.config.loading) {
      // const loading = Loading.service();
      // loading.close();
      requestCount--
      if (requestCount == 0) {//防止异步多个请求导致多个Loading闪烁
        time = setTimeout(() => {
          loadingInstance.close(); //关闭Loading加载
        }, 10)
      }
    }
    const res = response.data
    if (response.config.responseType === 'blob') {
      return response
    }
    if (res.err_no != 0) {
      Message({
        message: res.err_msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      if (res.err_no === 'A110001') {
        store.dispatch('user/logout')
      }
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    // const loading = Loading.service();
    // loading.close();
    loadingInstance.close(); //关闭Loading加载
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
