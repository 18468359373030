<template>
  <div class="tag">
    <!-- <div
      :class="['tag-item', { tagActive: activePath == e.path }]"
      v-for="(e, i) in tag"
      :key="i"
      @click.stop="handleClick(e)"
    >
      {{ e.label }}
      <i v-if="i" class="el-icon-close" @click="handleClose(e)"></i>
    </div> -->
    <div class="breadcrumb">
      <div class="dian"></div>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item v-for="(e, i) in matchedArr" :key="i">
            {{ e.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    activePath: {
      type: String,
      default: "",
    },
    matchedArr: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["tag"]),
  },
  methods: {
    handleClose(item) {
      let e = item
      this.$store.dispatch("user/delTag", item);
      if(this.tag.length==1){
        location.reload();
      }
    },
    handleClick(e) {
      this.$router.push(e.path);
    },
  },
};
</script>
<style>
/* 整个滚动条 */
::-webkit-scrollbar {
  /* 对应纵向滚动条的宽度 */
  width: 6px;
  /* 对应横向滚动条的宽度 */
  height: 8px;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
  background-color: #42b983;
  border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: #dbeffd;
  border-radius: 32px;
}
</style>
<style lang="scss" scoped>
.tag:hover {
  overflow-x: auto;
}
.dian{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: rgb(255, 177, 28);
  margin-right: 5px;
}
.breadcrumb{
  display: flex;
  align-items: center;
}
.tag {
  background-color: #fff;
  padding: 10px 15px;
  box-sizing: border-box;
  // width: calc(100vw - 230px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);

  .tag-item {
    flex-shrink: 0;
    margin-left: 6px;
    cursor: pointer;
    padding: 4px 24px 4px 18px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    position: relative;
    .el-icon-close {
      font-size: 14px;
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .tagActive {
    background-color: #42b983;
    color: #fff;
  }
  .tagActive::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 8px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fff;
    transform: translateY(-50%);
  }
}
</style>