<template>
  <div>
    <div class="tableBox">
      <el-table
        :cell-style="{ background: `${options.cellBackground || 'RGB(248,248,248)'}`, fontSize: $px2rem('14px') }"
        :header-cell-style="{ background: `${options.headerCellBackground || 'RGB(240,245,249)'}`, fontSize: $px2rem('16px'), color: '#666', }"
        :data="options.tableData" :style="{ width: '100%' }" :border="options.border" v-fit-columns
        :row-key="options.rowKey" :default-expand-all="options.defaultExpandAll"
        :tree-props="{ children: `${options.children || ''}`, }">
        <!-- :height="innerHeight() - (options.height || 300)" -->
        <!-- v-fit-columns -->
        <el-table-column v-if="options.selection" type="selection" width="55">
        </el-table-column>
        <el-table-column v-if="options.index" label="序号" align="center" type="index" width="50">
        </el-table-column>
        <template v-for="(item, index) in options.column">
          <el-table-column v-if="item.type == 'switch'" :align="item.align || 'center'" :prop="item.prop"
            :label="item.label" :min-width="item.width" :key="index" :formatter="item.formatter">
            <template slot-scope="scope">
              <el-switch v-model="scope.row[item.prop]" active-color="#13ce66" inactive-color="#ff4949"
                :active-value="item.activeValue" :inactive-value="item.inactiveValue"
                @change="switchChange($event, scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column v-if="item.type == 'render'" :align="item.align || 'center'" :prop="item.prop"
            :label="item.label" :min-width="item.width" :key="index" :formatter="item.formatter">
            <template slot-scope="scope">
              {{ renderToHtml(item, scope.row) }}
              <slot :name="item.prop"></slot>
            </template>
          </el-table-column>
          <el-table-column :sortable="item.sortable" :sort-method="item.sortMethod" :sort-by="item.sortBy"
            v-if="!item.type && differentMenu(item)" :align="item.align || 'center'" :prop="item.prop"
            :label="item.label" :min-width="item.width" :key="item.label" :formatter="item.formatter" :render-header="(h, { column, $index }) =>
          renderHeader(
            h,
            { column, $index },
            item.customHeader,
            item.label,
            item.show
          )
          ">
          </el-table-column>
        </template>
        <el-table-column align="center" label="操作" :min-width="options.btnWidth || 100" v-if="options.operate"
          fixed="right">
          <!-- <template slot-scope="{ row, column }">
            <slot name="opt" :row="row" :column="column"></slot>
          </template> -->
          <template v-slot="scope">
            <div v-if="options.btnList.length > 1">
              <el-dropdown trigger="click" @command="allEvent">
                <span class="el-dropdown-link" style="cursor: pointer;color: rgb(64, 158, 255);">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item, index) in options.btnList" :key="index"
                    :command="{ item, index: scope.$index, row: scope.row }">
                    <div v-if="btnShow(item, scope.row)"
                      :style="`color:${item.color || 'rgb(64, 158, 255)'};text-align:center;`">{{ item.label }}</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div v-else>
              <div v-debounce class="btn_color" :style="`color:${e.color || 'rgb(64, 158, 255)'};`"
                v-for="(e, i) in options.btnList" :key="i"
                @click="allEvent({ item: e, index: scope.$index, row: scope.row })">
                {{ e.label }}
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBox" v-if="!options.pageFlag">
      <Pagination :pageData="options.pageData" @handleCurrentChange="currentChange"></Pagination>
    </div>
  </div>
</template>

<script>
import Pagination from "../pagination";
export default {
  components: {
    Pagination,
  },
  props: {
    options: {
      type: Object,
      default: () => { },
    },
    status: {
      type: [String, Number],
      default: () => '',
    },
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    //table不同状态显示
    differentMenu(item) {
      if (!item.menuStatus) return true
      if (item.menuStatus.some(v => v === this.status)) return true
      return false
    },
    //操作按钮显示
    btnShow(item, row) {
      let myFn = item.fn;
      if (!item.valueArr || !item.valueArr.length) return true
      return myFn ? item.valueArr.some(v => row[item.value] == v) && myFn(row) : item.valueArr.some(v => row[item.value] == v)
    },
    //开关
    switchChange(val, row) {
      this.$emit('switchChange', val, row)
    },
    //脱敏
    hidden() {
      this.options.tableData.forEach(e => {
        this.options.column.forEach((v) => {
          if (v.hiddenType == '姓名' && !v.show) {
            if (e[v.prop]) e[v.prop] = '**' + e[v.prop].substr(-1, 1)
          }
          if (v.hiddenType == '手机号' && !v.show) {
            if (e[v.prop]) e[v.prop] = e[v.prop].replace(/^(.{3})(?:\d+)(.{4})$/, '$1****$2')
          }
          if (v.hiddenType == '身份证' && !v.show) {
            if (e[v.prop]) e[v.prop] = e[v.prop].replace(/^(.{6})(?:\d+)(.{4})$/, '$1********$2')
          }
        })
      })
    },
    //点击头部
    btnHeader(name) {
      this.options.column.forEach((v, i) => {
        if (v.prop == name) {
          v.show = !v.show
        }
      })
      this.$emit("onLoad");
    },
    renderHeader(h, { column, $index }, customHeader, label, show) {
      if (!customHeader) {
        return <span>{label}</span>
      } else {
        if (show) {
          return (
            <div>
              <span>{label}</span>
              <img
                style="width:20px;height:12px;cursor: pointer;vertical-align: middle;margin-left:4px;"
                src="https://cdn.usharejob.com/uploads/images/2022/12/06/3b9318953ebf13162d0abcea1e475963.png"
                alt="404"
                onClick={() => this.btnHeader(column.property)}
              />
            </div>
          )
        } else {
          return (
            <div>
              <span>{label}</span>
              <img
                style="width:20px;height:12px;cursor: pointer;vertical-align: middle;margin-left:4px;"
                src="https://cdn.usharejob.com/uploads/images/2022/12/06/bdecf8b6c04453aec062f60d2ce6b04e.png"
                alt="404"
                onClick={() => this.btnHeader(column.property)}
              />
            </div>
          )
        }
      }
    },
    innerHeight() {
      let height = window.innerHeight;
      // return `${height - (this.options.height || 300)}`;
      return `${this.options.height || 300}`;
    },
    allEvent(e) {
      let { item, row } = e;
      this.$emit(item.prop, row);
    },
    renderToHtml(item, row, inx) {
      if (typeof item.render === "function") {
        this.$slots[item.prop] = [item.render(row, inx)];
        return;
      }
      return;
    },
    currentChange(val) {
      this.$emit("onLoad", { page: val });
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-table__body-wrapper {
    overflow: auto !important;
  }
}

.btn_color {
  cursor: pointer;
  color: #409eff;
}

.tableBox {
  margin-bottom: 20px;
}

.paginationBox {
  margin-top: 20px;
  text-align: center;
}
</style>