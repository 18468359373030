import request from '@/utils/request'
//短信图形验证码
export function getCaptchaImg() {
  return request({
    url: '/api/common/captcha/img',
    method: 'get',
    responseType: 'blob',
    requestBaseUrl: 'api',
  })
}
//账号密码图形验证码
export function getCaptchaImg2() {
  return request({
    url: '/api/adminapi/login/captcha',
    method: 'post'
  })
}
//手机登录
export function loginByPhone(query) {
  return request({
    url: '/api/adminapi/login/loginByPhone',
    method: 'post',
    data: query
  })
}
//账户登录
export function loginByName(query) {
  return request({
    url: '/api/adminapi/login/loginByName',
    method: 'post',
    data: query
  })
}
//发送验证码
export function sendCode(query) {
  return request({
    url: '/api/common/sms/sendCode',
    method: 'post',
    data: query,
    requestBaseUrl: 'api',
    loading: true
  })
}
//管理列表
export function adminList(query) {
  return request({
    url: '/api/adminapi/v1/system/adminApi/adminList',
    method: 'post',
    data: query,
    loading: true
  })
}
//角色无分页列表
export function roleNoPage(query) {
  return request({
    url: '/api/adminapi/v1/system/adminApi/roleNoPage',
    method: 'post',
    data: query
  })
}
//管理员禁用、启用
export function adminStatus(query) {
  return request({
    url: '/api/adminapi/v1/system/adminApi/adminStatus',
    method: 'post',
    data: query,
    loading: true
  })
}
//管理员添加
export function adminAdd(query) {
  return request({
    url: '/api/adminapi/v1/system/adminApi/adminAdd',
    method: 'post',
    data: query,
    loading: true
  })
}
//管理员编辑
export function adminEdit(query) {
  return request({
    url: '/api/adminapi/v1/system/adminApi/adminEdit',
    method: 'post',
    data: query,
    loading: true
  })
}
//全部权限
export function authIndex(query) {
  return request({
    url: '/api/adminapi/v1/system/auth/index',
    method: 'post',
    data: query,
    loading: true
  })
}
//全部权限状态
export function authStatus(query) {
  return request({
    url: '/api/adminapi/v1/system/auth/status',
    method: 'post',
    data: query,
    loading: true
  })
}
//权限列表
export function authList(query) {
  return request({
    url: '/api/adminapi/v1/system/adminApi/authList',
    method: 'post',
    data: query,
    loading: true
  })
}
//权限添加
export function authAdd(query) {
  return request({
    url: '/api/adminapi/v1/system/auth/add',
    method: 'post',
    data: query,
    loading: true
  })
}
//权限修改
export function authEdit(query) {
  return request({
    url: '/api/adminapi/v1/system/auth/edit',
    method: 'post',
    data: query,
    loading: true
  })
}
//角色列表
export function roleList(query) {
  return request({
    url: '/api/adminapi/v1/system/adminApi/roleList',
    method: 'post',
    data: query,
    loading: true
  })
}
//角色禁用、启用
export function roleStatus(query) {
  return request({
    url: '/api/adminapi/v1/system/adminApi/roleStatus',
    method: 'post',
    data: query,
    loading: true
  })
}
//角色添加
export function roleAdd(query) {
  return request({
    url: '/api/adminapi/v1/system/adminApi/roleAdd',
    method: 'post',
    data: query,
    loading: true
  })
}
//角色详情
export function roleDetail(query) {
  return request({
    url: '/api/adminapi/v1/system/adminApi/roleDetail',
    method: 'post',
    data: query,
    loading: true
  })
}
//角色编辑
export function roleEdit(query) {
  return request({
    url: '/api/adminapi/v1/system/adminApi/roleEdit',
    method: 'post',
    data: query,
    loading: true
  })
}
//修改密码
export function adminEditPwd(query) {
  return request({
    url: '/api/adminapi/v1/system/adminApi/adminEditPwd',
    method: 'post',
    data: query,
    loading: true
  })
}
//banner配置列表
export function bannerList(query) {
  return request({
    url: '/api/adminapi/v1/configuration/banner/list',
    method: 'post',
    data: query,
    loading: true
  })
}
//banner投放位置
export function bannerPosition(query) {
  return request({
    url: '/api/adminapi/v1/configuration/banner/position',
    method: 'post',
    data: query,
    loading: true
  })
}
//banner新建
export function bannerAdd(query) {
  return request({
    url: '/api/adminapi/v1/configuration/banner/add',
    method: 'post',
    data: query,
    loading: true
  })
}
//banner修改
export function bannerEdit(query) {
  return request({
    url: '/api/adminapi/v1/configuration/banner/edit',
    method: 'post',
    data: query,
    loading: true
  })
}
//banner删除
export function delBaner(query) {
  return request({
    url: '/api/adminapi/v1/configuration/banner/delBaner',
    method: 'post',
    data: query,
    loading: true
  })
}
//banner禁用
export function statusBaner(query) {
  return request({
    url: '/api/adminapi/v1/configuration/banner/status',
    method: 'post',
    data: query,
    loading: true
  })
}


//弹框配置列表
export function popupList(query) {
  return request({
    url: '/api/adminapi/v1/configuration/popup/list',
    method: 'post',
    data: query,
    loading: true
  })
}
//弹框投放位置
export function popupPosition(query) {
  return request({
    url: '/api/adminapi/v1/configuration/popup/position',
    method: 'post',
    data: query,
    loading: true
  })
}
//弹框新建
export function popupAdd(query) {
  return request({
    url: '/api/adminapi/v1/configuration/popup/add',
    method: 'post',
    data: query,
    loading: true
  })
}
//弹框修改
export function popupEdit(query) {
  return request({
    url: '/api/adminapi/v1/configuration/popup/edit',
    method: 'post',
    data: query,
    loading: true
  })
}
//弹框删除
export function delPopup(query) {
  return request({
    url: '/api/adminapi/v1/configuration/popup/delPopup',
    method: 'post',
    data: query,
    loading: true
  })
}
//弹框禁用
export function statusPopup(query) {
  return request({
    url: '/api/adminapi/v1/configuration/popup/status',
    method: 'post',
    data: query,
    loading: true
  })
}
//师傅列表
export function masterList(query) {
  return request({
    url: '/api/adminapi/v1/staff/master/list',
    method: 'post',
    data: query,
    loading: true
  })
}
//师傅添加
export function masterAdd(query) {
  return request({
    url: '/api/adminapi/v1/staff/master/add',
    method: 'post',
    data: query,
    loading: true
  })
}
//师傅详细
export function masterInfo(query) {
  return request({
    url: '/api/adminapi/v1/staff/master/info',
    method: 'post',
    data: query,
    loading: true
  })
}
//师傅修改
export function masterEdit(query) {
  return request({
    url: '/api/adminapi/v1/staff/master/edit',
    method: 'post',
    data: query,
    loading: true
  })
}
//师傅修改状态
export function masterStatus(query) {
  return request({
    url: '/api/adminapi/v1/staff/master/status',
    method: 'post',
    data: query,
    loading: true
  })
}
//师傅列表导出
export function masterListExport(query) {
  return request({
    url: '/api/adminapi/v1/staff/master/listExport',
    method: 'post',
    data: query,
    loading: true
  })
}
//企业列表
export function companyList(query) {
  return request({
    url: '/api/adminapi/v1/staff/company/list',
    method: 'post',
    data: query,
    loading: true
  })
}
//企业添加
export function companyAdd(query) {
  return request({
    url: '/api/adminapi/v1/staff/company/add',
    method: 'post',
    data: query,
    loading: true
  })
}
//服务范围
export function categoryList(query) {
  return request({
    url: '/api/adminapi/v1/staff/category/list',
    method: 'post',
    data: query,
    loading: true
  })
}
//标准产品列表
export function serviceList(query) {
  return request({
    url: '/api/adminapi/v1/product/service/list',
    method: 'post',
    data: query,
    loading: true
  })
}
//标准产品添加
export function serviceAdd(query) {
  return request({
    url: '/api/adminapi/v1/product/service/add',
    method: 'post',
    data: query,
    loading: true
  })
}
//标准产品修改
export function serviceEdit(query) {
  return request({
    url: '/api/adminapi/v1/product/service/edit',
    method: 'post',
    data: query,
    loading: true
  })
}
//标准产品下架
export function serviceStatus(query) {
  return request({
    url: '/api/adminapi/v1/product/service/status',
    method: 'post',
    data: query,
    loading: true
  })
}
//标准产品导出
export function serviceListExport(query) {
  return request({
    url: '/api/adminapi/v1/product/service/listExport',
    method: 'post',
    data: query,
    loading: true
  })
}
//订单列表
export function serviceOrderList(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceOrder/list',
    method: 'post',
    data: query,
    loading: true
  })
}
//订单列表修改
export function serviceOrderEdit(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceOrder/edit',
    method: 'post',
    data: query,
    loading: true
  })
}
//订单列表添加
export function serviceOrderAdd(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceOrder/add',
    method: 'post',
    data: query,
    loading: true
  })
}
//订单列表禁用
export function serviceOrderStatus(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceOrder/status',
    method: 'post',
    data: query,
    loading: true
  })
}
//订单列表导出
export function serviceOrderListExport(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceOrder/listExport',
    method: 'post',
    data: query,
    loading: true
  })
}
//订单列表详细
export function serviceOrderInfo(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceOrder/info',
    method: 'post',
    data: query,
    loading: true
  })
}
//订单收支列表
export function financialList(query) {
  return request({
    url: '/api/adminapi/v1/financial/serviceOrder/financialList',
    method: 'post',
    data: query,
    loading: true
  })
}
//订单收支列表导出
export function financialListExport(query) {
  return request({
    url: '/api/adminapi/v1/financial/serviceOrder/financialListExport',
    method: 'post',
    data: query,
    loading: true
  })
}
//订单收支完成
export function finish(query) {
  return request({
    url: '/api/adminapi/v1/financial/serviceOrder/finish',
    method: 'post',
    data: query,
    loading: true
  })
}
//用户列表
export function userList(query) {
  return request({
    url: '/api/adminapi/v1/user/member/list',
    method: 'post',
    data: query,
    loading: true
  })
}
//用户列表导出
export function userListExport(query) {
  return request({
    url: '/api/adminapi/v1/user/member/listExport',
    method: 'post',
    data: query,
    loading: true
  })
}
//用户列表详细
export function infoUser(query) {
  return request({
    url: '/api/adminapi/v1/user/member/infoUser',
    method: 'post',
    data: query,
    loading: true
  })
}
//用户列表删除
export function statusUser(query) {
  return request({
    url: '/api/adminapi/v1/user/member/statusUser',
    method: 'post',
    data: query,
    loading: true
  })
}
//用户列表修改备注
export function modifyRemarks(query) {
  return request({
    url: '/api/adminapi/v1/user/member/modifyRemarks',
    method: 'post',
    data: query,
    loading: true
  })
}
//用户列表修改手机
export function editPhone(query) {
  return request({
    url: '/api/adminapi/v1/user/member/editPhone',
    method: 'post',
    data: query,
    loading: true
  })
}
//用户列表修改默认地址
export function setDefault(query) {
  return request({
    url: '/api/adminapi/v1/user/member/setDefault',
    method: 'post',
    data: query,
    loading: true
  })
}
//需求列表
export function listNeed(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceNeedOrder/listNeed',
    method: 'post',
    data: query,
    loading: true
  })
}
//需求详细
export function detailNeed(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceNeedOrder/detailNeed',
    method: 'post',
    data: query,
    loading: true
  })
}
//修改状态
export function statusNeed(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceNeedOrder/statusNeed',
    method: 'post',
    data: query,
    loading: true
  })
}
//需求列表导出
export function listNeedExport(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceNeedOrder/listNeedExport',
    method: 'post',
    data: query,
    loading: true
  })
}
//今日数据
export function todayData(query) {
  return request({
    url: '/api/adminapi/v1/system/home/todayData',
    method: 'post',
    data: query,
    loading: true
  })
}
//订单数据
export function orderData(query) {
  return request({
    url: '/api/adminapi/v1/system/home/orderData',
    method: 'post',
    data: query,
    loading: true
  })
}
//日活
export function dailyActivity(query) {
  return request({
    url: '/api/adminapi/v1/system/home/dailyActivity',
    method: 'post',
    data: query,
    loading: true
  })
}
//当月销售额和线上订单数
export function monthData(query) {
  return request({
    url: '/api/adminapi/v1/system/home/monthData',
    method: 'post',
    data: query,
    loading: true
  })
}
//添加用户
export function addUser(query) {
  return request({
    url: '/api/adminapi/v1/user/member/addUser',
    method: 'post',
    data: query,
    loading: true
  })
}
//用户修改公司
export function modifyUserCompany(query) {
  return request({
    url: '/api/adminapi/v1/user/member/modifyUserCompany',
    method: 'post',
    data: query,
    loading: true
  })
}
//客服列表
export function customerService(query) {
  return request({
    url: '/api/adminapi/v1/configuration/customerService/index',
    method: 'post',
    data: query,
    loading: true
  })
}
//修改状态
export function customerServiceStatus(query) {
  return request({
    url: '/api/adminapi/v1/configuration/customerService/status',
    method: 'post',
    data: query,
    loading: true
  })
}
//添加客服
export function customerServiceAdd(query) {
  return request({
    url: '/api/adminapi/v1/configuration/customerService/add',
    method: 'post',
    data: query,
    loading: true
  })
}
//修改
export function customerServiceEdit(query) {
  return request({
    url: '/api/adminapi/v1/configuration/customerService/edit',
    method: 'post',
    data: query,
    loading: true
  })
}
//确认支付
export function payStatus(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceOrder/payStatus',
    method: 'post',
    data: query,
    loading: true
  })
}
//获取地区
export function getArea(query) {
  return request({
    url: '/api/adminapi/v1/system/home/getArea',
    method: 'post',
    data: query,
    loading: true
  })
}
//日历
export function calendar(query) {
  return request({
    url: '/api/adminapi/v1/staff/master/calendar',
    method: 'post',
    data: query,
    loading: true
  })
}
//添加服务记录
export function addRecord(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceOrder/addRecord',
    method: 'post',
    data: query,
    loading: true
  })
}
//修改服务记录
export function editRecord(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceOrder/editRecord',
    method: 'post',
    data: query,
    loading: true
  })
}
//删除
export function delRecord(query) {
  return request({
    url: '/api/adminapi/v1/product/serviceOrder/delRecord',
    method: 'post',
    data: query,
    loading: true
  })
}
