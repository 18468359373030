<template>
  <div class="main">
    <!-- :include="keepFn(tag)" -->
    <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <router-view :key="key"></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["tag"]),
    key() {
      return this.$route.path
    }
  },
  methods: {
    keepFn(tag) {
      let arr = tag.map(m => m.name);
      let str = arr.join()
      return str || '';
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  flex: 1;
  padding: 20px;
  font-size: 17px;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
}
</style>