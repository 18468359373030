<template>
  <div class="pagination">
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="pageData.page" :page-size="pageData.pageSize" :layout="layout" :total="pageData.total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    pageData: {
      type: Object,
      default: () => ({
        page: 1,
        pageSize: 10,
        total: 0,
      }),
    },
    layout: {
      type: String,
      default: "prev, pager, next, jumper, total",
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-pagination__total {
  float: right;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: RGB(255,177,28) !important;
  color: #FFF;
}
</style>
