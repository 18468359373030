<template>
  <div :class="{ menu: true, menuTrue: isCollapse }">
    <el-menu unique-opened :collapse="isCollapse" :default-active="defaultActive" :collapse-transition="false"
      class="el-menu" background-color="rgb(255,177,28)" text-color="#fff" active-text-color="#fff">
      <SideItem :menu="userInfo.menu"></SideItem>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SideItem from "./components/sideItem.vue";
export default {
  components: {
    SideItem,
  },
  props: {
    defaultActive: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menu: []
    };
  },
  computed: {
    ...mapGetters(["userInfo", 'isCollapse']),
  },
  created() {
    // this.menu = this.$router.options.routes.filter((f, i) => i > 3);
    // this.menu = this.userInfo?.menu || []

  },
  methods: {},
};
</script>

<style lang="scss" scoped>
/* 整个滚动条 */
::-webkit-scrollbar {
  /* 对应纵向滚动条的宽度 */
  width: 0px;
  /* 对应横向滚动条的宽度 */
  height: 0px;
}

/*隐藏文字*/
::v-deep .el-menu--collapse .el-submenu__title span {
  display: none !important;
}

/*隐藏 > */
::v-deep .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none !important;
}

::v-deep .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.menu {
  height: 100%;
  width: 200px;
  overflow: scroll;
  transition: 0.5s;

}

.menuTrue {
  width: 70px !important;
}

.el-menu {
  height: 100%;
  width: 100%;

}

::v-deep .el-submenu__title {
  text-align: left;
}

::v-deep .el-menu-item {
  text-align: left;
}
::v-deep .el-submenu{
  .el-menu-item{
    padding-left: 40px !important;

  }
}
</style>
