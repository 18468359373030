import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Layout from "@/layout";
Vue.use(VueRouter);

let routes = [
  {
    path: "/login",
    component: () => import("@/page/login.vue"),
    name: "login",
  },
  {
    path: "/404",
    component: () => import("@/page/404.vue"),
    name: "notfound",
  },
  {
    path: "/",
    redirect: { path: "/login" },
  },
  // {
  //   path: "*",
  //   redirect: { path: "/404" },
  // },
  // {
  //   id: 0,
  //   path: '/configCenter',
  //   component: Layout,
  //   redirect: '/configCenter/bannerConfig',
  //   meta: {
  //     title: '配置中心',
  //     icon: 'icon-caiwuguanli1',
  //     layer: false
  //   },
  //   children: [
  //     {
  //       id: 9,
  //       path: '/configCenter/bannerConfig',
  //       component: () => import('@/views/configCenter/bannerConfig'),
  //       name: 'bannerConfig',
  //       meta: {
  //         title: 'banner配置',
  //         // icon: 'icon-tuiguang'
  //       }
  //     },
  //     {
  //       id: 10,
  //       path: '/configCenter/popConfig',
  //       component: () => import('@/views/configCenter/popConfig'),
  //       name: 'popConfig',
  //       meta: {
  //         title: '弹框配置',
  //         // icon: 'icon-tuiguang'
  //       }
  //     }
  //   ]
  // },
  // {
  //   id: 0,
  //   path: '/taskCenter',
  //   component: Layout,
  //   redirect: '/taskCenter/orderList',
  //   meta: {
  //     title: '任务中心',
  //     icon: 'icon-caiwuguanli1',
  //     layer: false
  //   },
  //   children: [
  //     {
  //       id: 9,
  //       path: '/taskCenter/orderList',
  //       component: () => import('@/views/taskCenter/orderList'),
  //       name: 'orderList',
  //       meta: {
  //         title: '订单列表',
  //         // icon: 'icon-tuiguang'
  //       }
  //     },
  //     {
  //       id: 10,
  //       path: '/taskCenter/standardProduct',
  //       component: () => import('@/views/taskCenter/standardProduct'),
  //       name: 'standardProduct',
  //       meta: {
  //         title: '标准产品',
  //         // icon: 'icon-tuiguang'
  //       }
  //     }
  //   ]
  // },
  // {
  //   id: 0,
  //   path: '/financeCenter',
  //   component: Layout,
  //   redirect: '/financeCenter/orderExpense',
  //   meta: {
  //     title: '财务中心',
  //     icon: 'icon-caiwuguanli1',
  //     layer: false
  //   },
  //   children: [
  //     {
  //       id: 9,
  //       path: '/financeCenter/orderExpense',
  //       component: () => import('@/views/financeCenter/orderExpense'),
  //       name: 'orderExpense',
  //       meta: {
  //         title: '订单收支',
  //         // icon: 'icon-tuiguang'
  //       }
  //     }
  //   ]
  // },
  // {
  //   id: 0,
  //   path: '/masterManage',
  //   component: Layout,
  //   redirect: '/masterManage/masterList',
  //   meta: {
  //     title: '师傅管理',
  //     icon: 'icon-caiwuguanli1',
  //     layer: false
  //   },
  //   children: [
  //     {
  //       id: 9,
  //       path: '/masterManage/masterList',
  //       component: () => import('@/views/masterManage/masterList'),
  //       name: 'masterList',
  //       meta: {
  //         title: '师傅列表',
  //         // icon: 'icon-tuiguang'
  //       }
  //     }
  //   ]
  // },
  // {
  //   id: 0,
  //   path: '/system',
  //   component: Layout,
  //   redirect: '/',
  //   meta: {
  //     title: '系统设置',
  //     icon: 'icon-caiwuguanli1',
  //     layer: false
  //   },
  //   children: [
  //     {
  //       id: 9,
  //       path: '/system/personalCenter',
  //       component: () => import('@/views/system/personalCenter'),
  //       name: 'personalCenter',
  //       meta: {
  //         title: '个人中心',
  //         // icon: 'icon-tuiguang'
  //       }
  //     },
  //     {
  //       id: 10,
  //       path: '/system/manager',
  //       component: () => import('@/views/system/manager'),
  //       name: 'manager',
  //       meta: {
  //         title: '管理员管理',
  //         // icon: 'icon-tuiguang'
  //       }
  //     },
  //     {
  //       id: 10,
  //       path: '/system/role',
  //       component: () => import('@/views/system/role'),
  //       name: 'role',
  //       meta: {
  //         title: '角色管理',
  //         // icon: 'icon-tuiguang'
  //       }
  //     },
  //     {
  //       id: 10,
  //       path: '/system/serveAuth',
  //       component: () => import('@/views/system/serveAuth'),
  //       name: 'serveAuth',
  //       meta: {
  //         title: '路由权限管理',
  //         // icon: 'icon-tuiguang'
  //       }
  //     }
  //   ]
  // }
];
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
// routes = routes.concat(store.getters.menu);
const createRouter = () => new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
});
const router = createRouter()
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
export default router;
