<template>
  <div class="header">
    <div :class="{ logo: true, logo_false: isCollapse }">
      <img src="@/assets/yhll.png" class="logoImg" />
      <div class="logo_title" v-if="!isCollapse">SAAS后台</div>
    </div>
    <div class="header-right">
      <div class="breadcrumb">
        <i :class="{ 'el-icon-s-fold': true, 'xz_icon': isCollapse, 'xz_icon2': !isCollapse }" @click="switchMenu"></i>
      </div>
      <div class="userBox">
        <div class="title">嘉兴优禾邻里</div>
        <el-image :src="userInfo?.admin?.avatar" class="userImg"></el-image>
        <div class="title">{{ userInfo?.admin?.real_name }}</div>
        <el-dropdown @command="handleCommand" trigger="click" @visible-change="visibleChange">
          <span class="el-dropdown-link">
            <i :class="iconFlag ? 'el-icon-caret-top title' : 'el-icon-caret-bottom title'"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="gr">个人中心</el-dropdown-item>
            <el-dropdown-item command="logout">切换账号</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-image src="https://cdn.usharejob.com/uploads/images/2024/05/08/07feae31798816beaa3f5ae8cd2d30a1.png"
          class="quitImg" @click="openQuit"></el-image>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    matchedArr: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      iconFlag: false,
      list: [],
    };
  },

  computed: {
    ...mapGetters(['isCollapse', 'userInfo']),
  },

  created() {

  },

  methods: {
    visibleChange(val) {
      this.iconFlag = val
    },
    //退出登录
    openQuit() {
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: 'btnClass'
      }).then(() => {
        this.$store.dispatch("user/logout");
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //菜单收展
    switchMenu() {
      this.$store.dispatch("user/setCollapse");
    },
    async handleCommand(command) {
      if (command == "logout") {
        this.openQuit()
      } else {
        this.$router.push('/system/personalCenter')
      }
    },
  }
};
</script>
<style>
.btnClass {
  color: #FFF;
  background-color: #E6A23C !important;
  border-color: #E6A23C !important;
}
</style>
<style lang="scss" scoped>
.xz_icon {
  transform: rotate(-180deg);
  transition: 0.5s;
}

.xz_icon2 {
  transform: rotate(-360deg);
  transition: 0.5s;
}

.header {
  height: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  letter-spacing: 4px;
  justify-content: center;

  .channelName {
    font-size: 16px;
    color: rgb(58, 58, 58);
    font-weight: 700;
  }

  .logo_false {
    width: 70px !important;
  }

  .logo {
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 177, 28);
    font-size: 20px;
    color: #fff;
    letter-spacing: 2px;
    transition: 0.5s;
    white-space: nowrap;

    .logo_title {
      margin-left: 5px;
      font-weight: 700;
    }

    .logoImg2 {
      width: 100%;
      height: 100%;
    }

    .logoImg {
      width: 40px;
      height: 25px;
    }
  }

  .header-right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border-bottom: 1px solid RGB(226, 226, 226);
    box-sizing: border-box;
  }

  .breadcrumb {
    padding-left: 10px;
    box-sizing: border-box;
    font-size: 30px;
    cursor: pointer;
  }

  ::v-deep .el-breadcrumb__inner {
    color: #fff !important;
    font-size: 17px !important;
    letter-spacing: 1px !important;
  }

  ::v-deep .el-icon-arrow-right {
    color: #fff !important;
  }

  .quitImg {
    width: 18px;
    height: 20px;
    margin: 0 35px 0 35px;
    cursor: pointer;
  }

  .userImg {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .el-dropdown-link {
    cursor: pointer;
    text-align: left;
    color: #fff;
  }

  .userBox {
    display: flex;
    align-items: center;
    padding-right: 20px;
    box-sizing: border-box;

    .title {
      font-size: 16px;
      color: rgb(58, 58, 58);
      font-weight: 700;
    }
  }
}
</style>